<div class="main-layout">
  <!-- Sidebar -->
  <div class="sidebar">
    <div class="logo">
      <img src="./assets/images/logo.png" alt="" srcset="" />
    </div>
    <div class="menu">
      <ul>
        <li *ngFor="let menuItem of menu" [pTooltip]="menuItem.label" [autoHide]="false">
          <a [routerLink]="menuItem.routerLink">
            <img
              src="./assets/icons/{{ menuItem.icon }}{{
                onIsActiveRoute(menuItem.routerLink) ? '-active' : ''
              }}.svg"
              [alt]="menuItem.label"
              srcset=""
            />
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Content -->
  <div class="content">
    <!-- Navbar -->
    <div class="navbar">
      <div class="title">
        {{ pageTitle }}
      </div>
      <div class="actions">
        <p-avatar
          label="{{ activeUser?.firstName?.[0] }}{{ activeUser?.lastName?.[0] }}"
          styleClass="mr-2"
          class="user-avatar"
          shape="circle"
          (click)="userOverflow.toggle($event)"
        ></p-avatar>
        <p-overlayPanel [dismissable]="true" #userOverflow [style]="{ width: '350px' }">
          <ng-template pTemplate="content">
            <div
              *ngIf="!isChangePassword; else changePasswordTemplate"
              class="flex flex-column flex-wrap gap-5"
            >
              <div class="flex align-items-center flex-wrap flex-column">
                <p-avatar
                  label="{{ activeUser?.firstName?.[0] }}{{ activeUser?.lastName?.[0] }}"
                  styleClass="mr-2"
                  class="user-avatar"
                  shape="circle"
                  size="xlarge"
                ></p-avatar>
                <p class="text-user">{{ activeUser?.firstName }} {{ activeUser?.lastName }}</p>
              </div>
              <div class="flex flex-column profile-menu">
                <p>{{ activeUser?.email }}</p>
                <p>{{ activeUser?.company?.name }}</p>
                <p>{{ activeUser?.company?.companyId }}</p>
                <button
                  type="button"
                  (click)="onTermsConditions()"
                  class="flex justify-content-between align-items-center gap-1"
                >
                  <span>Terms and Conditions</span>
                  <i class="pi pi-angle-right"></i>
                </button>
                <button
                  type="button"
                  (click)="onPrivacyPolicy()"
                  class="flex justify-content-between align-items-center gap-1"
                >
                  <span>Privacy Policy</span>
                  <i class="pi pi-angle-right"></i>
                </button>
                <button
                  type="button"
                  (click)="onChangePassword()"
                  class="flex justify-content-between align-items-center gap-1"
                >
                  <span>Password</span>
                  <i class="pi pi-angle-right"></i>
                </button>
                <p-divider></p-divider>
                <button
                  type="button"
                  (click)="logout()"
                  class="flex justify-content-between align-items-center gap-1"
                >
                  <span>Log out</span>
                  <i class="pi pi-sign-out"></i>
                </button>
              </div>
            </div>
            <ng-template #changePasswordTemplate>
              <form [formGroup]="form" (ngSubmit)="onSubmitChangePassword(userOverflow)">
                <div class="flex flex-column profile-menu">
                  <div class="flex align-items-center gap-3">
                    <button type="button" (click)="onChangePassword()">
                      <i class="pi pi-angle-left"></i>
                    </button>
                    <span>Password</span>
                  </div>
                </div>

                <div class="form-field-area">
                  <div class="mt-2 col-12">
                    <div class="flex flex-column gap-2">
                      <label htmlFor="oldPassword">Old Password</label>
                      <p-password
                        [toggleMask]="true"
                        formControlName="oldPassword"
                        inputStyleClass="w-full"
                        styleClass="w-full"
                        [feedback]="false"
                      ></p-password>
                      <app-form-error-message formName="oldPassword"></app-form-error-message>
                      <div style="margin-top: -8px">
                        <small class="form-error-message" *ngIf="invalidCurrentPasswordMessage"
                          >{{ invalidCurrentPasswordMessage }}.</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 col-12">
                    <div class="flex flex-column gap-2">
                      <label htmlFor="newPassword">New Password</label>
                      <p-password
                        [toggleMask]="true"
                        formControlName="newPassword"
                        inputStyleClass="w-full"
                        styleClass="w-full"
                      ></p-password>
                      <app-form-error-message formName="newPassword"></app-form-error-message>
                    </div>
                  </div>
                  <div class="mt-2 col-12">
                    <div class="flex flex-column gap-2">
                      <label htmlFor="newPasswordConfirm">Confirm New Password</label>
                      <p-password
                        [toggleMask]="true"
                        formControlName="newPasswordConfirm"
                        inputStyleClass="w-full"
                        styleClass="w-full"
                      ></p-password>
                      <app-form-error-message formName="newPasswordConfirm"></app-form-error-message>
                    </div>
                  </div>
                  <div class="mt-2 col-12">
                    <p-button
                      label="Change"
                      type="submit"
                      [loading]="isLoadingChangePassword"
                      styleClass="p-button-rounded w-full px-5"
                    ></p-button>
                  </div>
                </div>
              </form>
            </ng-template>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
