import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services';
import { AlertService } from '../service';
import { Router } from '@angular/router';

/**
 * Interceptor for set authorization token and refresh token
 */

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  private _isRefreshing = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _authService: AuthService,
    private _alertService: AlertService,
    private _router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this._authService.getAuthenticatedToken();

    if (accessToken) {
      request = this._setToken(request, accessToken);
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          this._authService.getAuthenticatedUser()?.refreshToken
        ) {
          if (error.error.name) {
            this._isRefreshing = false;
            this._authService.removeAuthenticatedSession();
            this._alertService.showInfoToast({ message: 'Your login session has expired.' });
            this._router.navigate(['/auth/login']);
            return throwError(error);
          }

          return this._handle401Error(request, next);
        } else {
          if (error.status === 401) {
            this._authService.removeAuthenticatedSession();
            this._alertService.showInfoToast({ message: 'Your login session has ended.' });
            this._router.navigate(['/auth/login']);
          }
          return throwError(error);
        }
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _setToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._refreshTokenSubject.next(null);
      const refreshToken = this._authService.getAuthenticatedUser()?.refreshToken;

      return this._authService.refreshToken(refreshToken).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        switchMap((res: any) => {
          this._isRefreshing = false;
          this._refreshTokenSubject.next(res.accessToken);
          return next.handle(this._setTokenHeader(request, res.accessToken));
        }),
        catchError(err => {
          this._isRefreshing = false;
          return throwError(() => err);
        }),
      );
    }
    return this._refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this._setTokenHeader(request, token))),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _setTokenHeader(request: HttpRequest<any>, token: string) {
    if (token) {
      return (request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }));
    } else {
      return request;
    }
  }
}
