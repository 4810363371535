<div class="auth-layout">
  <div class="auth-container">
    <div class="logo">
      <img src="./assets/images/my-part-pros-lg.svg" alt="My Part Pros" />
    </div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="auth-background"></div>
</div>
