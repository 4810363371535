import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-error-message',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngIf="isNotValid">
      <small class="form-error-message" *ngIf="formControl.hasError('required')">
        This field is required.
      </small>
      <small class="form-error-message" *ngIf="formControl.hasError('email')">Not a valid email. </small>
      <small class="form-error-message" *ngIf="formControl.hasError('isDuplicateEmail')"
        >Email already exists.</small
      >
      <small class="form-error-message" *ngIf="formControl.hasError('confirmPassword')"
        >Both passwords must match.
      </small>
      <small
        class="form-error-message"
        *ngIf="formControl.hasError('zipCode') && !formControl.hasError('required')"
        >Zip Code must have at least 5 digits.
      </small>
    </ng-container>
  `,
  styles: [
    `
      .form-error-message {
        color: var(--danger-color);
        position: absolute;
        margin-top: -5px;
      }
    `,
  ],
})
export class FormErrorMessageComponent {
  @Input() formName = '';

  constructor(private controlContainer: ControlContainer) {}

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get formControl(): AbstractControl {
    return this.form.get(this.formName) as AbstractControl;
  }

  get isNotValid() {
    return this.formControl.invalid && this.formControl.touched;
  }
}
