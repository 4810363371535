<div class="AppBar">
  <div class="logo" flex="2">
    <img src="./assets/images/logo.png" alt="" srcset="" />
  </div>
  <h1 class="LogoColor">My Part Pros</h1>
  <div class="pull-right">
    <p-button label="Decline" styleClass="p-button-third" type="button" (onClick)="onDecline()">
    </p-button>
    <p-button
      label="Accept"
      styleClass="w-full flex justify-content-between align-items-center pull-right"
      type="button"
      (onClick)="onAccept()"
    >
    </p-button>
  </div>
</div>
<div class="WordSection1">
  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black">Privacy Policy</span>
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black">Last updated: January 24, 2024</span>
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of
      Your information when You use the Service and tells You about Your privacy rights and how the law
      protects You.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We use Your Personal data to provide and improve the Service. By using the Service, You agree to
      the collection and use of information in accordance with this Privacy Policy.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Interpretation and Definitions</span
    >
  </h2>

  <h3>Interpretation</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The words of which the initial letter is capitalized have meanings defined under the following
      conditions. The following definitions shall have the same meaning regardless of whether they appear
      in singular or in plural.</span
    >
  </p>

  <h3>Definitions</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >For the purposes of this Privacy Policy:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Account</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      means a unique account created for You to access our Service or parts of our Service.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Affiliate</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      means an entity that controls, is controlled by or is under common control with a party, where
      &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other
      securities entitled to vote for election of directors or other managing authority.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Application</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      refers to My Part Pros, the software program provided by the Company.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Business</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >, for the purpose of CCPA/CPRA, refers to the Company as the legal entity that collects Consumers'
      personal information and determines the purposes and means of the processing of Consumers' personal
      information, or on behalf of which such information is collected and that alone, or jointly with
      others, determines the purposes and means of the processing of consumers' personal information,
      that does business in the State of California.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">CCPA</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      and/or <b>CPRA</b> refers to the California Consumer Privacy Act (the &quot;CCPA&quot;) as amended
      by the California Privacy Rights Act of 2020 (the &quot;CPRA&quot;).</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Company</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
      in this Agreement) refers to My Part Pros LLC, PO Box 90523, Sioux Falls, SD 57109.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Consumer</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >, for the purpose of the CCPA/CPRA, means a natural person who is a California resident. A
      resident, as defined in the law, includes (1) every individual who is in the USA for other than a
      temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is
      outside the USA for a temporary or transitory purpose.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Cookies</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      are small files that are placed on Your computer, mobile device or any other device by a website,
      containing the details of Your browsing history on that website among its many uses.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Country</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      refers to: South Dakota, United States</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Device</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      means any device that can access the Service such as a computer, a cellphone or a digital
      tablet.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Do Not Track</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S.
      Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for
      allowing internet users to control the tracking of their online activities across websites.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Personal Data</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      is any information that relates to an identified or identifiable individual.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >For the purposes of the CCPA/CPRA, Personal Data means any information that identifies, relates
      to, describes or is capable of being associated with, or could reasonably be linked, directly or
      indirectly, with You.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Service</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      refers to the Application or the Website or both.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Service Provider</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      means any natural or legal person who processes the data on behalf of the Company. It refers to
      third-party companies or individuals employed by the Company to facilitate the Service, to provide
      the Service on behalf of the Company, to perform services related to the Service or to assist the
      Company in analyzing how the Service is used.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Usage Data</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      refers to data collected automatically, either generated by the use of the Service or from the
      Service infrastructure itself (for example, the duration of a page visit).</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Website</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      refers to My Part Pros, accessible from </span
    ><a href="http://mypartpros.com/"
      ><span style="font-family: 'Cambria', serif; color: black">mypartpros.com</span></a
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">You</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      means the individual accessing or using the Service, or the company, or other legal entity on
      behalf of which such individual is accessing or using the Service, as applicable.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Collecting and Using Your Personal Data</span
    >
  </h2>

  <h3>Types of Data Collected</h3>

  <h4>Personal Data</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >While using Our Service, We may ask You to provide Us with certain personally identifiable
      information that can be used to contact or identify You. Personally identifiable information may
      include, but is not limited to:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Email address</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">First name and last name</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Phone number</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Address, State, Province, ZIP/Postal code, City</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Usage Data</span>
  </p>

  <h4>Usage Data</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Usage Data is collected automatically when using the Service.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
      address), browser type, browser version, the pages of our Service that You visit, the time and date
      of Your visit, the time spent on those pages, unique device identifiers and other diagnostic
      data.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >When You access the Service by or through a mobile device, We may collect certain information
      automatically, including, but not limited to, the type of mobile device You use, Your mobile device
      unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
      Internet browser You use, unique device identifiers and other diagnostic data.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may also collect information that Your browser sends whenever You visit our Service or when You
      access the Service by or through a mobile device.</span
    >
  </p>

  <h4>Tracking Technologies and Cookies</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We use Cookies and similar tracking technologies to track the activity on Our Service and store
      certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
      information and to improve and analyze Our Service. The technologies We use may include:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">Cookies or Browser Cookies.</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies
      or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be
      able to use some parts of our Service. Unless you have adjusted Your browser setting so that it
      will refuse Cookies, our Service may use Cookies.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">Web Beacons.</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      Certain sections of our Service and our emails may contain small electronic files known as web
      beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the
      Company, for example, to count users who have visited those pages or opened an email and for other
      related website statistics (for example, recording the popularity of a certain section and
      verifying system and server integrity).</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on
      Your personal computer or mobile device when You go offline, while Session Cookies are deleted as
      soon as You close Your web browser. You can learn more about cookies on </span
    ><a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
      ><span style="font-family: 'Cambria', serif; color: black">TermsFeed website</span></a
    ><span style="font-family: 'Cambria', serif; color: black"> article.</span>
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We use both Session and Persistent Cookies for the purposes set out below:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Necessary / Essential Cookies</span></b>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Type: Session Cookies</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Administered by: Us</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Purpose: These Cookies are essential to provide You with services available through the Website
      and to enable You to use some of its features. They help to authenticate users and prevent
      fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot
      be provided, and We only use these Cookies to provide You with those services.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Cookies Policy / Notice Acceptance Cookies</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Type: Persistent Cookies</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Administered by: Us</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Functionality Cookies</span></b>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Type: Persistent Cookies</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Administered by: Us</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
      remembering your login details or language preference. The purpose of these Cookies is to provide
      You with a more personal experience and to avoid You having to re-enter your preferences every time
      You use the Website.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >For more information about the cookies we use and your choices regarding cookies, please visit our
      Cookies Policy or the Cookies section of our Privacy Policy.</span
    >
  </p>

  <h3>Background Operation Terms</h3>

  <h4>1. Background Functionality</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Our app requires permission to run in the background to:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      Track usage time and manage task assignments by unassigning parts from users based on the duration
      the app has been active. Maintain a continuous connection with your Bluetooth barcode scanner for
      seamless scanning operations.</span
    >
  </p>

  <h4>2. Data Collection</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      While running in the background, our app collects data on:
    </span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      App Usage Time: The total time the app has been running to manage task assignments effectively.
      Bluetooth Connection Status: Information related to the status and stability of the Bluetooth
      connection with the barcode scanner. Task Management Data: Information related to assigned and
      unassigned tasks to optimize workflow.</span
    >
  </p>

  <h4>3. Data Usage</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      The data collected while the app is running in the background will be used to:
    </span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      Monitor and track the duration of app usage. Automatically unassign parts from users based on the
      tracked time. Maintain a stable connection with the Bluetooth barcode scanner to ensure
      uninterrupted scanning operations. Improve task management and ensure timely task
      assignments.</span
    >
  </p>

  <h4>4. Battery Consumption</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      Running the app in the background may consume additional battery power. We optimize our processes
      to minimize battery usage while ensuring essential services remain active.</span
    >
  </p>

  <h4>5. User Control</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      You have control over the app's background activity. You can disable background operation at any
      time through the app settings or your device settings. Note that disabling background activity may
      limit the app's functionality related to task management and Bluetooth connectivity.</span
    >
  </p>

  <h4>6. Privacy Policy</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >For more detailed information on how we handle your data, please refer to our
      <a href="https://dealer.mypartpros.com/terms-conditions"
        >https://dealer.mypartpros.com/terms-conditions</a
      >.
    </span>
  </p>

  <h4>7. Consent</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      By allowing our app to run in the background, you consent to the collection and use of your data as
      described in these terms and our Privacy Policy.
    </span>
  </p>

  <h4>8. Contact Us</h4>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">
      If you have any questions or concerns about these terms, please contact us at info@mypartpros.com
      Example Explanation for the App Store Listing Permission for Background Operation Our app, My Part
      Pros, requires permission to run in the background to track app usage time, manage task assignments
      efficiently, and maintain a continuous connection with your Bluetooth barcode scanner. By
      monitoring how long the app has been active, we can automatically unassign parts from users based
      on the duration of use, ensuring optimal workflow and task management. Additionally, maintaining a
      stable Bluetooth connection allows for seamless scanning operations. We are committed to
      maintaining your privacy and ensuring that all data collected during background operation is used
      solely for improving your experience with our app. You can manage background operation permissions
      in the app settings.
    </span>
  </p>

  <h3>Use of Your Personal Data</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black">
      The Company may use Personal Data for the following purposes:
    </span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b>
      <span style="font-family: 'Cambria', serif; color: black">
        To provide and maintain our Service
      </span>
    </b>
    <span style="font-family: 'Cambria', serif; color: black">
      , including to monitor the usage of our Service.
    </span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">To manage Your Account:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      to manage Your registration as a user of the Service. The Personal Data You provide can give You
      access to different functionalities of the Service that are available to You as a registered
      user.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >For the performance of a contract:</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      the development, compliance and undertaking of the purchase contract for the products, items or
      services You have purchased or of any other contract with Us through the Service.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">To contact You:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
      communication, such as a mobile application's push notifications regarding updates or informative
      communications related to the functionalities, products or contracted services, including the
      security updates, when necessary or reasonable for their implementation.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">To provide You</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      with news, special offers and general information about other goods, services and events which we
      offer that are similar to those that you have already purchased or enquired about unless You have
      opted not to receive such information.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">To manage Your requests:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      To attend and manage Your requests to Us.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">For business transfers:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
      reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a
      going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data
      held by Us about our Service users is among the assets transferred.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">For other purposes</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >: We may use Your information for other purposes, such as data analysis, identifying usage trends,
      determining the effectiveness of our promotional campaigns and to evaluate and improve our Service,
      products, services, marketing and your experience.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may share Your personal information in the following situations:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">With Service Providers:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      We may share Your personal information with Service Providers to monitor and analyze the use of our
      Service, to contact You.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">For business transfers:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      We may share or transfer Your personal information in connection with, or during negotiations of,
      any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business
      to another company.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">With Affiliates:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      We may share Your information with Our affiliates, in which case we will require those affiliates
      to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries,
      joint venture partners or other companies that We control or that are under common control with
      Us.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">With business partners:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      We may share Your information with Our business partners to offer You certain products, services or
      promotions.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">With other users:</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      when You share personal information or otherwise interact in the public areas with other users,
      such information may be viewed by all users and may be publicly distributed outside.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">With Your consent</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >: We may disclose Your personal information for any other purpose with Your consent.</span
    >
  </p>

  <h3>Retention of Your Personal Data</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The Company will retain Your Personal Data only for as long as is necessary for the purposes set
      out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to
      comply with our legal obligations (for example, if we are required to retain your data to comply
      with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
      retained for a shorter period of time, except when this data is used to strengthen the security or
      to improve the functionality of Our Service, or We are legally obligated to retain this data for
      longer time periods.</span
    >
  </p>

  <h3>Transfer of Your Personal Data</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Your information, including Personal Data, is processed at the Company's operating offices and in
      any other places where the parties involved in the processing are located. It means that this
      information may be transferred to — and maintained on — computers located outside of Your state,
      province, country or other governmental jurisdiction where the data protection laws may differ than
      those from Your jurisdiction.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Your consent to this Privacy Policy followed by Your submission of such information represents
      Your agreement to that transfer.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The Company will take all steps reasonably necessary to ensure that Your data is treated securely
      and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to
      an organization or a country unless there are adequate controls in place including the security of
      Your data and other personal information.</span
    >
  </p>

  <h3>Delete Your Personal Data</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >You have the right to delete or request that We assist in deleting the Personal Data that We have
      collected about You.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Our Service may give You the ability to delete certain information about You from within the
      Service.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >You may update, amend, or delete Your information at any time by signing in to Your Account, if
      you have one, and visiting the account settings section that allows you to manage Your personal
      information. You may also contact Us to request access to, correct, or delete any personal
      information that You have provided to Us.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please note, however, that We may need to retain certain information when we have a legal
      obligation or lawful basis to do so.</span
    >
  </p>

  <h3>Disclosure of Your Personal Data</h3>

  <h4>Business Transactions</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
      transferred. We will provide notice before Your Personal Data is transferred and becomes subject to
      a different Privacy Policy.</span
    >
  </p>

  <h4>Law enforcement</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Under certain circumstances, the Company may be required to disclose Your Personal Data if
      required to do so by law or in response to valid requests by public authorities (e.g. a court or a
      government agency).</span
    >
  </p>

  <h4>Other legal requirements</h4>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The Company may disclose Your Personal Data in the good faith belief that such action is necessary
      to:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">Comply with a legal obligation</span>
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Protect and defend the rights or property of the Company</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Prevent or investigate possible wrongdoing in connection with the Service</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Protect the personal safety of Users of the Service or the public</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">Protect against legal liability</span>
  </p>

  <h3>Security of Your Personal Data</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The security of Your Personal Data is important to Us, but remember that no method of transmission
      over the Internet, or method of electronic storage is 100% secure. While We strive to use
      commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
      security.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >CCPA/CPRA Privacy Notice (California Privacy Rights)</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >This privacy notice section for California residents supplements the information contained in Our
      Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
      California.</span
    >
  </p>

  <h3>Categories of Personal Information Collected</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We collect information that identifies, relates to, describes, references, is capable of being
      associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
      or Device. The following is a list of categories of personal information which we may collect or
      may have been collected from California residents within the last twelve (12) months.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please note that the categories and examples provided in the list below are those defined in the
      CCPA/CPRA. This does not mean that all examples of that category of personal information were in
      fact collected by Us, but reflects our good faith belief to the best of Our knowledge that some of
      that information from the applicable category may be and may have been collected. For example,
      certain categories of personal information would only be collected if You provided such personal
      information directly to Us.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Category A: Identifiers.</span></b>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: A real name, alias, postal address, unique personal identifier, online identifier,
      Internet Protocol address, email address, account name, driver's license number, passport number,
      or other similar identifiers.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: Yes.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category B: Personal information categories listed in the California Customer Records statute
        (Cal. Civ. Code § 1798.80(e)).</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: A name, signature, Social Security number, physical characteristics or description,
      address, telephone number, passport number, driver's license or state identification card number,
      insurance policy number, education, employment, employment history, bank account number, credit
      card number, debit card number, or any other financial information, medical information, or health
      insurance information. Some personal information included in this category may overlap with other
      categories.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: Yes.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category C: Protected classification characteristics under California or federal law.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion
      or creed, marital status, medical condition, physical or mental disability, sex (including gender,
      gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual
      orientation, veteran or military status, genetic information (including familial genetic
      information).</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category D: Commercial information.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Records and history of products or services purchased or considered.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category E: Biometric information.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns
      used to extract a template or other identifier or identifying information, such as, fingerprints,
      faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and
      sleep, health, or exercise data.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category F: Internet or other similar network activity.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Interaction with our Service or advertisement.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: Yes.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Category G: Geolocation data.</span></b>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Approximate physical location.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b><span style="font-family: 'Cambria', serif; color: black">Category H: Sensory data.</span></b>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category I: Professional or employment-related information.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Current or past job history or performance evaluations.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category J: Non-public education information (per the Family Educational Rights and Privacy Act
        (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Education records directly related to a student maintained by an educational institution
      or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student
      identification codes, student financial information, or student disciplinary records.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category K: Inferences drawn from other personal information.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
      predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: No.</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <b
      ><span style="font-family: 'Cambria', serif; color: black"
        >Category L: Sensitive personal information.</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Examples: Account login and password information, geolocation data.</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Collected: Yes.</span>
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Under CCPA/CPRA, personal information does not include:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Publicly available information from government records</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Deidentified or aggregated consumer information</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Information excluded from the CCPA/CPRA's scope, such as:
    </span>
  </p>

  <p class="MsoListBullet2CxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Health or medical information covered by the Health Insurance Portability and Accountability Act
      of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical
      trial data</span
    >
  </p>

  <p class="MsoListBullet2CxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Personal Information covered by certain sector-specific privacy laws, including the Fair Credit
      Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy
      Act (FIPA), and the Driver's Privacy Protection Act of 1994</span
    >
  </p>

  <h3>Sources of Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We obtain the categories of personal information listed above from the following categories of
      sources:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">Directly from You</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >. For example, from the forms You complete on our Service, preferences You express or provide
      through our Service.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">Indirectly from You</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >. For example, from observing Your activity on our Service.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">Automatically from You</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >. For example, through cookies We or our Service Providers set on Your Device as You navigate
      through our Service.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">From Service Providers</span></b
    ><span style="font-family: 'Cambria', serif; color: black"
      >. For example, or other third-party vendors that We use to provide the Service to You.</span
    >
  </p>

  <h3>Use of Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may use or disclose personal information We collect for &quot;business purposes&quot; or
      &quot;commercial purposes&quot; (as defined under the CCPA/CPRA), which may include the following
      examples:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >To operate our Service and provide You with Our Service.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >To provide You with support and to respond to Your inquiries, including to investigate and address
      Your concerns and monitor and improve our Service.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >To fulfill or meet the reason You provided the information. For example, if You share Your contact
      information to ask a question about our Service, We will use that personal information to respond
      to Your inquiry.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >To respond to law enforcement requests and as required by applicable law, court order, or
      governmental regulations.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >As described to You when collecting Your personal information or as otherwise set forth in the
      CCPA/CPRA.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >For internal administrative and auditing purposes.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
      activity, including, when necessary, to prosecute those responsible for such activities.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">Other one-time uses.</span>
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please note that the examples provided above are illustrative and not intended to be exhaustive.
      For more details on how we use this information, please refer to the &quot;Use of Your Personal
      Data&quot; section.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If We decide to collect additional categories of personal information or use the personal
      information We collected for materially different, unrelated, or incompatible purposes, We will
      update this Privacy Policy.</span
    >
  </p>

  <h3>Disclosure of Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may use or disclose and may have used or disclosed in the last twelve (12) months the following
      categories of personal information for business or commercial purposes:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Category A: Identifiers</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Category B: Personal information categories listed in the California Customer Records statute
      (Cal. Civ. Code § 1798.80(e))</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Category F: Internet or other similar network activity</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please note that the categories listed above are those defined in the CCPA/CPRA. This does not
      mean that all examples of that category of personal information were in fact disclosed, but
      reflects our good faith belief to the best of our knowledge that some of that information from the
      applicable category may be and may have been disclosed.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >When We disclose personal information for a business purpose or a commercial purpose, We enter a
      contract that describes the purpose and requires the recipient to both keep that personal
      information confidential and not use it for any purpose except performing the contract.</span
    >
  </p>

  <h3>Share of Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may share, and have shared in the last twelve (12) months, Your personal information identified
      in the above categories with the following categories of third parties:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Service Providers</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Our affiliates</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Our business partners</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Third party vendors to whom You or Your agents authorize Us to disclose Your personal information
      in connection with products or services We provide to You</span
    >
  </p>

  <h3>Sale of Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot; mean selling, renting,
      releasing, disclosing, disseminating, making available, transferring, or otherwise communicating
      orally, in writing, or by electronic or other means, a Consumer's personal information by the
      Business to a third party for valuable consideration. This means that We may have received some
      kind of benefit in return for sharing personal information, but not necessarily a monetary
      benefit.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We do not sell personal information as the term sell is commonly understood. We do allow Service
      Providers to use Your personal information for the business purposes described in Our Privacy
      Policy, and these may be deemed a sale under CCPA/CPRA.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may sell and may have sold in the last twelve (12) months the following categories of personal
      information:</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black">Category A: Identifiers</span>
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Category B: Personal information categories listed in the California Customer Records statute
      (Cal. Civ. Code § 1798.80(e))</span
    >
  </p>

  <p class="MsoNormal" style="margin-left: 0.5in">
    <span style="font-family: 'Cambria', serif; color: black"
      >Category F: Internet or other similar network activity</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please note that the categories listed above are those defined in the CCPA/CPRA. This does not
      mean that all examples of that category of personal information were in fact sold, but reflects our
      good faith belief to the best of Our knowledge that some of that information from the applicable
      category may be and may have been shared for value in return.</span
    >
  </p>

  <h3>Sale of Personal Information of Minors Under 16 Years of Age</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We do not knowingly collect personal information from minors under the age of 16 through our
      Service, although certain third party websites that we link to may do so. These third-party
      websites have their own terms of use and privacy policies and We encourage parents and legal
      guardians to monitor their children's Internet usage and instruct their children to never provide
      information on other websites without their permission.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We do not sell the personal information of Consumers We actually know are less than 16 years of
      age, unless We receive affirmative authorization (the &quot;right to opt-in&quot;) from either the
      Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than
      13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future
      sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may
      submit a request to Us by contacting Us.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If You have reason to believe that a child under the age of 13 (or 16) has provided Us with
      personal information, please contact Us with sufficient detail to enable Us to delete that
      information.</span
    >
  </p>

  <h3>Your Rights under the CCPA/CPRA</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >The CCPA/CPRA provides California residents with specific rights regarding their personal
      information. If You are a resident of California, You have the following rights:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">The right to notice.</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right to be notified which categories of Personal Data are being collected and the
      purposes for which the Personal Data is being used.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b><span style="font-family: 'Cambria', serif; color: black">The right to know/access.</span></b
    ><span style="font-family: 'Cambria', serif; color: black">
      Under CCPA/CPRA, You have the right to request that We disclose information to You about Our
      collection, use, sale, disclosure for business purposes and share of personal information. Once We
      receive and confirm Your request, We will disclose to You:
    </span>
  </p>

  <p class="MsoListBullet2CxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The categories of personal information We collected about You</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The categories of sources for the personal information We collected about You</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Our business or commercial purposes for collecting or selling that personal information</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The categories of third parties with whom We share that personal information</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The specific pieces of personal information We collected about You</span
    >
  </p>

  <p class="MsoListBullet2CxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >If we sold Your personal information or disclosed Your personal information for a business
      purpose, We will disclose to You:
    </span>
  </p>

  <p class="MsoListBullet3CxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The categories of personal information categories sold</span
    >
  </p>

  <p class="MsoListBullet3CxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >The categories of personal information categories disclosed</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b
      ><span style="font-family: 'Cambria', serif; color: black"
        >The right to say no to the sale or sharing of Personal Data (opt-out).</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right to direct Us to not sell Your personal information. To submit an opt-out
      request, please see the &quot;Do Not Sell My Personal Information&quot; section or contact
      Us.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b
      ><span style="font-family: 'Cambria', serif; color: black"
        >The right to correct Personal Data.</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right to correct or rectify any inaccurate personal information about You that We
      collected. Once We receive and confirm Your request, We will use commercially reasonable efforts to
      correct (and direct our Service Providers to correct) Your personal information, unless an
      exception applies.</span
    >
  </p>

  <p class="MsoListBulletCxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b
      ><span style="font-family: 'Cambria', serif; color: black"
        >The right to limit use and disclosure of sensitive Personal Data.</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right to request to limit the use or disclosure of certain sensitive personal
      information We collected about You, unless an exception applies. To submit, please see the
      &quot;Limit the Use or Disclosure of My Sensitive Personal Information&quot; section or contact
      Us.</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b
      ><span style="font-family: 'Cambria', serif; color: black"
        >The right to delete Personal Data.</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right to request the deletion of Your Personal Data under certain circumstances,
      subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct
      Our Service Providers to delete) Your personal information from our records, unless an exception
      applies. We may deny Your deletion request if retaining the information is necessary for Us or Our
      Service Providers to:
    </span>
  </p>

  <p class="MsoListBullet2CxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Complete the transaction for which We collected the personal information, provide a good or
      service that You requested, take actions reasonably anticipated within the context of our ongoing
      business relationship with You, or otherwise perform our contract with You.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity,
      or prosecute those responsible for such activities.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Debug products to identify and repair errors that impair existing intended functionality.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Exercise free speech, ensure the right of another consumer to exercise their free speech rights,
      or exercise another right provided for by law.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et.
      seq.).</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Engage in public or peer-reviewed scientific, historical, or statistical research in the public
      interest that adheres to all other applicable ethics and privacy laws, when the information's
      deletion may likely render impossible or seriously impair the research's achievement, if You
      previously provided informed consent.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Enable solely internal uses that are reasonably aligned with consumer expectations based on Your
      relationship with Us.</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">Comply with a legal obligation.</span>
  </p>

  <p class="MsoListBullet2CxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Make other internal and lawful uses of that information that are compatible with the context in
      which You provided it.</span
    >
  </p>

  <p class="MsoListBullet">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><b
      ><span style="font-family: 'Cambria', serif; color: black"
        >The right not to be discriminated against.</span
      ></b
    ><span style="font-family: 'Cambria', serif; color: black">
      You have the right not to be discriminated against for exercising any of Your consumer's rights,
      including by:
    </span>
  </p>

  <p class="MsoListBullet2CxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">Denying goods or services to You</span>
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Charging different prices or rates for goods or services, including the use of discounts or other
      benefits or imposing penalties</span
    >
  </p>

  <p class="MsoListBullet2CxSpMiddle">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Providing a different level or quality of goods or services to You</span
    >
  </p>

  <p class="MsoListBullet2CxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Suggesting that You will receive a different price or rate for goods or services or a different
      level or quality of goods or services</span
    >
  </p>

  <h3>Exercising Your CCPA/CPRA Data Protection Rights</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Please see the &quot;Do Not Sell My Personal Information&quot; section and &quot;Limit the Use or
      Disclosure of My Sensitive Personal Information&quot; section for more information on how to opt
      out and limit the use of sensitive information collected.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Additionally, in order to exercise any of Your rights under the CCPA/CPRA, and if You are a
      California resident, You can contact Us:</span
    >
  </p>

  <p class="MsoListBullet">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">By email: </span
    ><a href="mailto:info@mypartpros.com"
      ><span style="font-family: 'Cambria', serif">info@mypartpros.com</span></a
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Only You, or a person registered with the California Secretary of State that You authorize to act
      on Your behalf, may make a verifiable request related to Your personal information.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black">Your request to Us must:</span>
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Provide sufficient information that allows Us to reasonably verify You are the person about whom
      We collected personal information or an authorized representative</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and
      respond to it</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We cannot respond to Your request or provide You with the required information if We cannot:</span
    >
  </p>

  <p class="MsoListBulletCxSpFirst">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >Verify Your identity or authority to make the request</span
    >
  </p>

  <p class="MsoListBulletCxSpLast">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black"
      >And confirm that the personal information relates to You</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We will disclose and deliver the required information free of charge within 45 days of receiving
      Your verifiable request. The time period to provide the required information may be extended once
      by an additional 45 days when reasonably necessary and with prior notice.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
      receipt.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >For data portability requests, We will select a format to provide Your personal information that
      is readily usable and should allow You to transmit the information from one entity to another
      entity without hindrance.</span
    >
  </p>

  <h3>Do Not Sell My Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot; mean selling, renting,
      releasing, disclosing, disseminating, making available, transferring, or otherwise communicating
      orally, in writing, or by electronic or other means, a Consumer's personal information by the
      Business to a third party for valuable consideration. This means that We may have received some
      kind of benefit in return for sharing personal information, but not necessarily a monetary
      benefit.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We do not sell personal information as the term sell is commonly understood. We do allow Service
      Providers to use Your personal information for the business purposes described in Our Privacy
      Policy, and these may be deemed a sale under CCPA/CPRA.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >You have the right to opt-out of the sale of Your personal information. Once We receive and
      confirm a verifiable consumer request from You, we will stop selling Your personal information. To
      exercise Your right to opt-out, please contact Us.</span
    >
  </p>

  <h3>Limit the Use or Disclosure of My Sensitive Personal Information</h3>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If You are a California resident, You have the right to limit the use and disclosure of Your
      sensitive personal information to that use which is necessary to perform the services or provide
      the goods reasonably expected by an average consumer who requests such services or goods.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We collect, use and disclose sensitive personal information in ways that are necessary to provide
      the Service. For more information on how We use Your personal information, please see the &quot;Use
      of Your Personal Data&quot; section or contact us.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >&quot;Do Not Track&quot; Policy as Required by California Online Privacy Protection Act
      (CalOPPA)</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Our Service does not respond to Do Not Track signals.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >However, some third party websites do keep track of Your browsing activities. If You are visiting
      such websites, You can set Your preferences in Your web browser to inform websites that You do not
      want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of
      Your web browser.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Your California Privacy Rights (California's Shine the Light law)</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Under California Civil Code Section 1798 (California's Shine the Light law), California residents
      with an established business relationship with us can request information once a year about sharing
      their Personal Data with third parties for the third parties' direct marketing purposes.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If you'd like to request more information under the California Shine the Light law, and if You are
      a California resident, You can contact Us using the contact information provided below.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >California Privacy Rights for Minor Users (California Business and Professions Code Section
      22581)</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >California Business and Professions Code Section 22581 allows California residents under the age
      of 18 who are registered users of online sites, services or applications to request and obtain
      removal of content or information they have publicly posted.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >To request removal of such data, and if You are a California resident, You can contact Us using
      the contact information provided below, and include the email address associated with Your
      account.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Be aware that Your request does not guarantee complete or comprehensive removal of content or
      information posted online and that the law may not permit or require removal in certain
      circumstances.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Children's Privacy</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Our Service does not address anyone under the age of 13. We do not knowingly collect personally
      identifiable information from anyone under the age of 13. If You are a parent or guardian and You
      are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
      that We have collected Personal Data from anyone under the age of 13 without verification of
      parental consent, We take steps to remove that information from Our servers.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If We need to rely on consent as a legal basis for processing Your information and Your country
      requires consent from a parent, We may require Your parent's consent before We collect and use that
      information.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Links to Other Websites</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >Our Service may contain links to other websites that are not operated by Us. If You click on a
      third party link, You will be directed to that third party's site. We strongly advise You to review
      the Privacy Policy of every site You visit.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We have no control over and assume no responsibility for the content, privacy policies or
      practices of any third party sites or services.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd"
      >Changes to this Privacy Policy</span
    >
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We may update Our Privacy Policy from time to time. We will notify You of any changes by posting
      the new Privacy Policy on this page.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >We will let You know via email and/or a prominent notice on Our Service, prior to the change
      becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy
      Policy.</span
    >
  </p>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >You are advised to review this Privacy Policy periodically for any changes. Changes to this
      Privacy Policy are effective when they are posted on this page.</span
    >
  </p>

  <h2 style="margin-top: 10pt; margin-right: 0in; margin-bottom: 0in; margin-left: 0in">
    <span style="font-size: 13pt; font-family: 'Calibri', sans-serif; color: #4f81bd">Contact Us</span>
  </h2>

  <p class="MsoNormal">
    <span style="font-family: 'Cambria', serif; color: black"
      >If you have any questions about this Privacy Policy, You can contact us:</span
    >
  </p>

  <p class="MsoListBullet">
    <span style="font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span
    ><span style="font-family: 'Cambria', serif; color: black">By email: </span
    ><a href="mailto:info@mypartpros.com"
      ><span style="font-family: 'Cambria', serif">info@mypartpros.com</span></a
    >
  </p>

  <p
    class="MsoNormal"
    style="
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 17.85pt;
      page-break-after: auto;
    "
  >
    <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
  </p>
</div>
