import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { authGuard, noAuthGuard } from './libs/guard';
import { TermsConditionsComponent } from './modules/auth/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './modules/auth/pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [noAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'warehouse',
        data: {
          title: 'Warehouse',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/warehouse/warehouse.module').then(module => module.WarehouseModule),
      },
      {
        path: 'setting',
        data: {
          title: 'Settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/setting/setting.module').then(module => module.SettingModule),
      },
      {
        path: 'superadmin',
        data: {
          title: 'Administration',
        },
        loadChildren: () =>
          import('./modules/superadmin/superadmin.module').then(module => module.SuperadminModule),
      },
      {
        path: 'productivity',
        data: {
          title: 'Productivity',
        },
        loadChildren: () =>
          import('./modules/productivity/productivity.module').then(module => module.ProductivityModule),
      },
      {
        path: 'sales',
        data: {
          title: 'Sales',
        },
        loadChildren: () => import('./modules/sales/sales.module').then(module => module.SalesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
