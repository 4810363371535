import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription, catchError, filter } from 'rxjs';
import { IMenuItems } from 'src/app/libs/interface';
import { AlertService, MenuService } from 'src/app/libs/service';
import { IAuthenticatedUser } from 'src/app/modules/auth/interfaces';
import { AuthService } from 'src/app/modules/auth/services';
import CustomValidators from 'src/app/shareds/validators/custom.validator';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  activeUser!: IAuthenticatedUser | null;
  form!: FormGroup;
  isChangePassword = false;
  isLoadingChangePassword = false;
  menu!: IMenuItems[];
  pageTitle!: string;
  routeSubscription!: Subscription;
  invalidCurrentPasswordMessage!: string;

  constructor(
    private readonly _menuService: MenuService,
    private _activeRoute: ActivatedRoute,
    private _alertService: AlertService,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _router: Router,
  ) {
    this.menu = this._menuService.getMenu();
    this.activeUser = this._authService.getAuthenticatedUser();

    // * Subscribe router
    this.routerSubscriber();
  }

  ngOnInit(): void {
    this._generateForm();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  /**
   * Generate reactive the form
   */
  _generateForm(): void {
    this.form = this._formBuilder.group(
      {
        oldPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
        newPasswordConfirm: new FormControl('', [Validators.required]),
      },
      {
        validators: [CustomValidators.confirmPassword('newPassword', 'newPasswordConfirm')],
      },
    );
  }

  /**
   * Remove authentication data and redirect to login page
   */
  logout() {
    this._authService.removeAuthenticatedSession();
    this._router.navigate(['/auth/login']);
  }

  /**
   * Stream router for get page title every page changes
   */
  routerSubscriber() {
    this.routeSubscription = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.pageTitle = this._activeRoute.snapshot.firstChild?.data['title'];
      });
  }

  onIsActiveRoute(path: string) {
    const urlPaths = this._router.url.split('/');
    return urlPaths[1] === path.split('/')[1];
  }

  /**
   * Trigger show change password form
   */
  onChangePassword(): void {
    this.isChangePassword = !this.isChangePassword;
  }

  /**
   * Navigate to privacy policy page
   */
  onTermsConditions() {
    this._router.navigate(['/terms-conditions']);
  }

  /**
   * Navigate to privacy policy page
   */
  onPrivacyPolicy() {
    this._router.navigate(['/privacy-policy']);
  }

  /**
   * Submit change password form to the API
   * @param {OverlayPanel} userOverflow
   */
  onSubmitChangePassword(userOverflow: OverlayPanel): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isLoadingChangePassword = true;
      this.invalidCurrentPasswordMessage = '';
      this._authService
        .changePassword(this.form.value)
        .pipe(
          catchError(error => {
            this.invalidCurrentPasswordMessage = error.error.message;
            this.isLoadingChangePassword = false;
            this.form.enable();
            throw error;
          }),
        )
        .subscribe({
          next: () => {
            this.isLoadingChangePassword = false;
            this._alertService.openInfoDialog('Success!', 'Your password has been changed!', 'Done');
            userOverflow.hide();
          },
        });
    }
  }
}
