import { Component, Input, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="flex flex-column gap-5">
      <div class="body">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
      </div>
      <div class="action flex justify-content-center flex-wrap">
        <div class="flex gap-5">
          <p-button
            *ngIf="cancelButtonLabel"
            [label]="cancelButtonLabel"
            (click)="closeDialog()"
            [disabled]="isLoading"
            styleClass="p-button-rounded p-button-secondary"
          ></p-button>
          <p-button
            *ngIf="cancelButtonLabel"
            [label]="confirmButtonLabel"
            (click)="onConfirmClick()"
            [loading]="isLoading"
            styleClass="p-button-rounded"
          ></p-button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule],
  providers: [DialogService, DynamicDialogModule],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() confirmButtonLabel = 'Yes';
  @Input() cancelButtonLabel = 'No';

  isLoading = false;

  constructor(
    public ref: DynamicDialogRef,
    private _dialogData: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.title = this._dialogData.data.title;
    this.message = this._dialogData.data.message;
    this.confirmButtonLabel = this._dialogData.data.confirmButtonLabel;
    this.cancelButtonLabel = this._dialogData.data.cancelButtonLabel;
  }

  /**
   * Close the dialog (cancel confirmation)
   */
  closeDialog() {
    this.ref.close();
  }

  /**
   * Confirm and close the dialog
   */
  async onConfirmClick() {
    this.isLoading = true;

    this._dialogData.data.confirmFunction().subscribe({
      next: () => {
        this.isLoading = false;
        this.ref.close({
          isConfirm: true,
        });
      },
    });
  }
}
