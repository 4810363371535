import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shareds/components/confirm-dialog/confirm-dialog.component';
import { IToast } from '../interface';
import { InfoDialogComponent } from 'src/app/shareds/components/info-dialog/info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    public dialogService: DialogService,
    private _messageService: MessageService,
  ) {}

  /**
   * @description Trigger confirm alert dialog
   * @param {String} message Displayed message
   * @param {Function} confirmFunction Function called when is confirmed
   * @param {Boolean} isDelete Is delete confirm or not
   * @returns Pop up confirm alert dialog
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openConfirmDialog(title: string, message: string, confirmFunction: any) {
    return this.dialogService.open(ConfirmDialogComponent, {
      styleClass: 'alert-dialog',
      showHeader: false,
      width: '33%',
      data: {
        title: title,
        message: message,
        confirmButtonLabel: 'Confirm',
        cancelButtonLabel: 'Cancel',
        confirmFunction: () => {
          return confirmFunction;
        },
      },
    });
  }

  /**
   * @description Trigger info alert dialog
   * @param {String} title Displayed title
   * @param {String} message Displayed message
   * @param {String} infoButton Displayed button
   * @returns Pop up info alert dialog
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openInfoDialog(title: string, message: string, infoButton: string) {
    return this.dialogService.open(InfoDialogComponent, {
      styleClass: 'alert-dialog',
      showHeader: false,
      data: {
        title: title,
        message: message,
        closeButtonLabel: infoButton,
      },
    });
  }

  /**
   * Show the success success toast
   * @param {IToast} payload: toast payload
   */
  showSuccessToast(payload: IToast) {
    this._messageService.add({
      severity: 'success',
      summary: payload.title ?? 'Success',
      detail: payload.message,
    });
  }

  /**
   * Show the info toast
   * @param {IToast} payload: toast payload
   */
  showInfoToast(payload: IToast) {
    this._messageService.add({
      severity: 'info',
      summary: payload.title ?? 'Info',
      detail: payload.message,
    });
  }

  /**
   * Show the error success toast
   * @param {IToast} payload: toast payload
   */
  showWarningToast(payload: IToast) {
    this._messageService.add({
      severity: 'warn',
      summary: payload.title ?? 'Warning',
      detail: payload.message,
    });
  }

  /**
   * Show the error success toast
   * @param {IToast} payload: toast payload
   */
  showErrorToast(payload: IToast) {
    this._messageService.add({
      severity: 'error',
      summary: payload.title ?? 'Error',
      detail: payload.message,
    });
  }
}
