import { Component, Input, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-info-dialog',
  template: `
    <div class="flex flex-column gap-5">
      <div class="body">
        <h4>{{ title }}</h4>
        <p>{{ message }}</p>
      </div>
      <div class="action flex justify-content-center flex-wrap">
        <div class="flex gap-5">
          <p-button
            *ngIf="closeButtonLabel"
            [label]="closeButtonLabel"
            (click)="closeDialog()"
            [disabled]="isLoading"
            styleClass="p-button-rounded"
          ></p-button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule],
  providers: [DialogService, DynamicDialogModule],
})
export class InfoDialogComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() closeButtonLabel!: string;

  isLoading = false;

  constructor(
    public ref: DynamicDialogRef,
    private _dialogData: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.title = this._dialogData.data.title;
    this.message = this._dialogData.data.message;
    this.closeButtonLabel = this._dialogData.data.closeButtonLabel;
  }

  /**
   * Close the dialog (cancel confirmation)
   */
  closeDialog() {
    this.ref.close();
  }
}
