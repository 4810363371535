import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class CustomValidators {
  /**
   * Custom validator for confirm password
   * @param {string} controlName: Password
   * @param {string} matchControlName: Confirm Password
   * @returns Confirm password validation
   */
  static confirmPassword(controlName: string, matchControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const matchControl = controls.get(matchControlName);

      if (!matchControl?.errors && control?.value !== matchControl?.value) {
        matchControl?.setErrors({
          confirmPassword: {
            actualValue: matchControl?.value,
            requiredValue: control?.value,
          },
        });
        return { confirmPassword: true };
      }
      return null;
    };
  }
}
