import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { AuthService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent {
  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _messageService: MessageService,
  ) {}

  onDecline() {
    this._router.navigate(['/auth/login']);
  }

  onAccept() {
    this._router.navigate(['/warehouse/all']);
  }
}
