import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAuthenticatedUser,
  IChangePassword,
  IForgotPassword,
  ILogin,
  INewPassword,
} from '../interfaces';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _endpoint = 'auth/';

  constructor(private _http: HttpClient) {}

  /**
   * Http login request
   * @param {ILogin} payload
   * @returns http login API
   */
  login(payload: ILogin) {
    return this._http.post<IAuthenticatedUser>(`${this._endpoint}login`, payload);
  }

  /**
   * Http change password
   * @param {IChangePassword} payload
   * @returns http change password API
   */
  changePassword(payload: IChangePassword) {
    return this._http.post<IAuthenticatedUser>(`${this._endpoint}change-password`, payload);
  }

  /**
   * Http refresh token
   * @param {string} refreshToken
   * @returns http refresh token API
   */
  refreshToken(refreshToken?: string) {
    return this._http
      .post(`${this._endpoint}refresh-token`, {
        refreshToken: refreshToken,
      })
      .pipe(tap(response => this.updateUserToken(response)));
  }

  /**
   * Http forgot password request
   * @param {IForgotPassword} payload
   * @returns http forgot password API
   */
  forgotPassword(payload: IForgotPassword) {
    return this._http.post(`${this._endpoint}forgot-password`, payload);
  }

  /**
   * Http get reset password token status request
   * @param {string} token reset password token
   * @returns http get token status API
   */
  getResetPasswordTokenStatus(token: string) {
    return this._http.get(`${this._endpoint}reset-password/${token}/status`);
  }

  /**
   * Http get new user token status request
   * @param {string} token
   * @returns http get token new user status API
   */
  getVerifyNewUserTokenStatus(token: string) {
    return this._http.get(`${this._endpoint}verify-new-user/${token}/status`);
  }

  /**
   * Http reset password request
   * @param {string} token reset password token
   * @param {IForgotPassword} payload
   * @returns http reset password API
   */
  resetPassword(token: string, payload: INewPassword) {
    return this._http.post(`${this._endpoint}reset-password/${token}`, payload);
  }

  /**
   * Http verify new user and set password
   * @param {string} token
   * @param {INewPassword} payload
   * @returns http verify new user API
   */
  verifyNewUser(token: string, payload: INewPassword) {
    return this._http.post(`${this._endpoint}verify-new-user/${token}`, payload);
  }

  /**
   * Save the authenticated user to the local storage
   * @param {IAuthenticatedUser} userData authenticated user data from login response
   */
  setAuthenticatedUser(userData: IAuthenticatedUser) {
    localStorage.setItem('user_data', JSON.stringify(userData));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserToken(userToken: any) {
    const userData = this.getAuthenticatedUser();
    if (userData) {
      userData.accessToken = userToken.accessToken;
      userData.refreshToken = userToken.refreshToken;
      this.setAuthenticatedUser(userData);
    }
  }

  /**
   * Get authenticated user data from local storage
   * @returns Authenticated user data or null if not found
   */
  getAuthenticatedUser(): IAuthenticatedUser | null {
    const userData = localStorage.getItem('user_data');
    if (userData) return JSON.parse(userData);
    return null;
  }

  /**
   * Get authorization API token
   * @returns Access token for the API authorization
   */
  getAuthenticatedToken(): string | null {
    return this.getAuthenticatedUser()?.accessToken ?? null;
  }

  /**
   * Remove all authorization data
   */
  removeAuthenticatedSession() {
    localStorage.removeItem('user_data');
  }

  /**
   * Get authenticated user status
   * @returns authenticated status
   */
  isAuthenticated(): boolean {
    if (this.getAuthenticatedToken()) return true;
    return false;
  }
}
