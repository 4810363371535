import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AlertService } from '../service';

/**
 * @description Interceptor for handle common http error
 */

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _alertService: AlertService,
    private _location: Location,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorResponse = error;
        switch (errorResponse.error.statusCode) {
          case 400:
            // * Specific error message
            if (typeof errorResponse.error.message !== 'string') {
              errorResponse.error.message.forEach((errorMessage: string) => {
                const checkResponseMessage = errorMessage.split('|');
                if (checkResponseMessage.length < 1) {
                  this._alertService.showErrorToast({ message: errorMessage });
                }
              });
            } else {
              const checkResponseMessage = errorResponse.error.message.split('|');
              if (checkResponseMessage.length < 1) {
                this._alertService.showErrorToast({ message: errorResponse.error.message });
              }
            }

            // * Common error message
            // this._alertService.showErrorToast({ message: 'Bad request, please contact administrator.' });
            break;
          case 402:
            // * Specific error message
            this._alertService.openInfoDialog(
              `${errorResponse.error.message}.`,
              `Please get in touch with My Part Pros for more licenses, or delete a warehouse before creating a new one.
            You can contact us at; sales@mypartpros.com
            or call (605) 610-0019`,
              'Okay',
            );

            // * Common error message
            // this._alertService.showErrorToast({ message: 'Bad request, please contact administrator.' });
            break;

          case 403:
            this._alertService.showWarningToast({
              message: errorResponse.error.message,
            });
            this._location.back();
            break;

          case 500:
            this._alertService.showErrorToast({
              message: 'Internal server error, please contact administrator.',
            });
            break;
        }
        return throwError(() => error);
      }),
    );
  }
}
