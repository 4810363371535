import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services';

/**
 * Guard for validate is authenticated or not, if authenticated will navigate to dashboard
 * @returns {boolean} true if unauthenticated and false if authenticated
 */
export const noAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);

  if (authService.isAuthenticated()) {
    authService.removeAuthenticatedSession();
  }
  return true;
};
