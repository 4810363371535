import { Injectable } from '@angular/core';
import { IMenuItems } from '../interface/menu';
import { AuthService } from 'src/app/modules/auth/services';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private _authService: AuthService) {}
  /**
   * Get menu data
   * @returns {IMenuItems} menu data
   */
  getMenu(): IMenuItems[] {
    const authenticatedUser = this._authService.getAuthenticatedUser();
    const rolesId = authenticatedUser?.roles?.map(role => role.id) ?? [];

    const menuItems = [];
    if (rolesId.includes('superadmin'))
      menuItems.push({
        label: 'Administration',
        icon: 'shield-person',
        routerLink: '/superadmin',
      });
    if (rolesId.includes('sales'))
      menuItems.push({
        label: 'Sales',
        icon: 'sales',
        routerLink: '/sales',
      });
    if (rolesId.includes('warehouse'))
      menuItems.push({
        label: 'Warehouse',
        icon: 'warehouse',
        routerLink: '/warehouse',
      });
    if (rolesId.includes('sales'))
      menuItems.push({
        label: 'Sales',
        icon: 'sales',
        routerLink: '/sales',
      });
    if (rolesId.includes('productivity'))
      menuItems.push({
        label: 'Productivity',
        icon: 'productivity',
        routerLink: '/productivity',
      });
    if (rolesId.includes('admin')) {
      menuItems.push({
        label: 'Sales',
        icon: 'sales',
        routerLink: '/sales',
      });
      menuItems.push({
        label: 'Warehouse',
        icon: 'warehouse',
        routerLink: '/warehouse',
      });
      menuItems.push({
        label: 'Productivity',
        icon: 'productivity',
        routerLink: '/productivity',
      });
      menuItems.push({
        label: 'Settings',
        icon: 'setting',
        routerLink: '/setting',
      });
    }

    return _.uniqBy(menuItems, 'label');
  }
}
